import React from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ReactGA from "react-ga";

/** Style Imports*/
import styled from "styled-components";
import GlobalStyle from "./GlobalStyle";

/** Custom Components*/
import Menu from 'components/Layout/Menu';
import SubSection from 'components/Layout/SubSection';

/** Containers */
import NotFound from 'components/Containers/NotFound';
import QRContainer from 'components/Containers/QRContainer';
import HojaSalaContainer from 'components/Containers/HojaSalaContainer';

/** Helpers */
import getComponentByRoute from "helpers/componentByRoutes";

/** Data */
import menu from "data/webMap";

/** Styles */
const MainContainer = styled.div`
  display: grid;
  grid-template-areas:
    "header header header"
    "left content right"
    "footer footer footer";
  grid-template-columns: ${({theme}) => `${theme.spacings.layoutMargin} 1fr ${theme.spacings.layoutMargin}`};
  grid-template-rows: ${({theme}) => theme.spacings.headerHeight} 1fr auto;
  grid-gap: 10px;
  height: 100vh;
  @media (max-width: ${({theme}) => theme.breakpoints.tabletLandscape}px) {
    grid-template-columns: ${({theme}) => `${theme.spacings.layoutSmallMargin} 1fr ${theme.spacings.layoutSmallMargin}`};
  }
  @media (min-width: ${({theme}) => theme.breakpoints.desktopLarge}px) {
    grid-template-columns: ${({theme}) => `1fr ${theme.breakpoints.desktop}px 1fr`};
  }
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px) {
    grid-template-columns: 0px 1fr 0px;
  }
`;
const HeaderSection = styled.header`
  grid-area: header;
`;
const MainSection = styled.main`
  grid-area: content;
`;

const Wrapper = styled.div`

  .fade-appear {
    opacity: 0.01;
    border: 1px solid red;
  }
  .fade-appear.fade-appear-active {
    opacity: 1;
    transition: all 2s ease 0s;
    border: 1px solid orange;
  }

  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

  div.transition-group {
    position: relative;
  }
`;

function Layout({ location }) {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <>
      <GlobalStyle />
      <Helmet>
        <link rel="shortcut icon" href='/assets/logo192.png' />
        <meta name="theme-color" content="#FFFFFF" />
        <meta name="description" content="Web oficial de Sergio Olmeda, artista visual" />
        <title>Sergio Olmeda</title>
      </Helmet>
      <MainContainer>
        <HeaderSection>
          <Menu menu={menu} />
        </HeaderSection>
        <MainSection>
          <Wrapper>
            <TransitionGroup className="transition-group">
              <CSSTransition
                key={location.key}
                timeout={300}
                classNames="fade"
                exit={false}
                >
                <div>
                  <Switch location={location}>
                    <Route exact path="/" component={getComponentByRoute('inicio')} />
                    {
                      menu.map(menuItem => {
                        const route = menuItem.url.split('/')[0];
                        const Component = getComponentByRoute(route);
                        return <Route key={menuItem._id} exact path={`/${route}`} component={Component} />
                      })
                    }
                    <Route exact path="/videochroma/:qrId" component={QRContainer} />
                    <Route exact path="/hoja-de-sala" component={HojaSalaContainer} />
                    <Route exact path="/:section/:page" component={SubSection} />
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Wrapper>
        </MainSection>
      </MainContainer>
    </>
  );
}
export default withRouter(Layout);