import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";


/** Styles */
const NavHolder = styled.div`
	grid-area: menu;
	align-self: center;
	ul {
		list-style: none;
		overflow: hidden;
		display: flex;
		margin: 0px;
		padding-inline-start: 0px;
	}
	a {
		display: flex;
		align-items: center;
		font-weight: bold;
		text-transform: uppercase;
		height: ${({theme}) => theme.spacings.headerHeight};
		cursor: pointer;
	}
`;

const MenuItem = styled.li`
	background-color: ${({selected}) => (selected) ? 'rgba(255,255,255,0.2)' : 'initial'};
  padding: 0 20px;
  text-decoration: none;
  text-align: center;
  color: ${({selected, theme}) => (selected) ? theme.colors.menu_items_selected_color : theme.colors.menu_items_color};
`;

function DesktopMenu({ menu, page }) {
  return (
    <NavHolder>
      <ul>
        {
          menu.map(menuItem => (
            <MenuItem selected={(page === menuItem.url)} key={menuItem._id}>
              <Link to={`/${menuItem.url}`}>
                {menuItem.title}
              </Link>
            </MenuItem>
          ))
        }
      </ul>
    </NavHolder>
  )
}

export default DesktopMenu;
