import React from "react";
import styled from "styled-components";

/** Helpers */
import isImage from "helpers/isImage";
import prettify from "helpers/prettify";

/** Data */
import feed from "data/feed";

const FeedBox = styled.div`
  display: grid;
  grid-template-columns: ${({cardWidth}) => `repeat(auto-fit, ${cardWidth});`};
  grid-gap: 20px;
  justify-content: space-between;
  text-align: ${({align}) => align};
  justify-items: ${({align}) => align};
  font-size: 10px;
  
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px){
    text-align: center;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, 100%);
    grid-gap: 20px;
    padding: 20px 0px;
    margin: 0px;
  }
`;

const Description = styled.div`
  margin-bottom: calc(${({theme}) => theme.spacings.descriptionGap} - 24px);
  text-align: initial;
  width: 65%;
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px){
    width: 100%;
    min-width: 100%;   
  }
`;

const DescriptionText = styled.p`
  font-size: 16px;
  margin-top: 0;
`;

const MediaContainer = styled.div`
  width: 65%;
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px){
    width: 100%;
    min-width: 100%;   
  }
`;

const Img = styled.img`
  margin-top: 12px;
  width: 100%;
  min-height: 150px;
  min-width: 730px;
  @media (max-width: ${({theme}) => theme.breakpoints.desktop}px){
    min-width: 450px;
    max-width: 450px;
  }
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px){
    min-width: 100%;
  }
  :hover {
    box-shadow: 0px 0px 10px ${({theme}) => theme.colors.cardHover};
    -webkit-box-shadow: 0px 0px 10px ${({theme}) => theme.colors.cardHover};
    -moz-box-shadow: 0px 0px 10px ${({theme}) => theme.colors.cardHover};
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
  }
  background-color: ${({theme}) => theme.colors.grey};
`;

const VideoContainer = styled.div`
  position: relative;
  width: 730px;
  height: 411px;
  @media (max-width: ${({theme}) => theme.breakpoints.desktop}px){
    width: 450px;
    height: 253px;
  }
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px){
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

function HomeContainer() {
  return (
    <>
      {
        feed.map((fd, index) => (
          <FeedBox align={fd.position} key={index}>
            <MediaContainer>
              {
                fd.media.map(media => {
                  return (isImage(media)) ?
                  (
                    <Img key={media} src={`/assets/feed/${media}`} alt={prettify(media)}></Img>
                  ) :
                  (
                    <VideoContainer key={media}>
                      <iframe
                        title={media}
                        src={`https://www.youtube.com/embed/${media}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                      </iframe>
                    </VideoContainer>
                  )
                })
              }
            </MediaContainer>
            <Description>
              {
                fd.text.map((__html, index) => (
                  <DescriptionText key={index} dangerouslySetInnerHTML={{ __html }} />
                ))
              }
            </Description>
          </FeedBox>
        ))
      }
    </>
  )
}

export default HomeContainer;