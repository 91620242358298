import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  :hover {
    color: ${({theme}) => theme.colors.cardHover};
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
  }
  @media (max-width: ${({theme}) => theme.breakpoints.transitionDesktopTablet}px) and (min-width: ${({theme}) => theme.breakpoints.tablet}px){
    max-width: 100%;
    max-height: ${({theme}) => theme.spacings.maxCardHeightOnTransition};
  }
`;

const CardImage = styled.div`
  :hover {
    box-shadow: 0px 0px 10px ${({theme}) => theme.colors.cardHover};
    -webkit-box-shadow: 0px 0px 10px ${({theme}) => theme.colors.cardHover};
    -moz-box-shadow: 0px 0px 10px ${({theme}) => theme.colors.cardHover};
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
  }
  background: url(${({src}) => src}) no-repeat;
  background-size: cover;
  background-position: ${({bgAlign}) => bgAlign};
  background-color: ${({theme}) => theme.colors.grey};
	width: ${({width}) => width || '250px'};
	height: ${({height}) => height || '178px'};
	padding: 10px;
  border-radius: ${({radius}) => radius || '5px'};
  animation: skeleton 2s infinite;
  @keyframes skeleton {
    0% {background-color: ${({theme}) => theme.colors.grey};}
    50% {background-color: ${({theme}) => theme.colors.darkGrey};}
    100% {background-color: ${({theme}) => theme.colors.grey};}
  }
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px) {
    width: ${({width}) => 
      (width && parseInt(width.substr(0, width.length - 2)) > 150) 
        ? '100%' 
        : width || '250px'
    };
	  height: ${({width, height}) => 
      (height && width && parseInt(width.substr(0, width.length - 2)) > 150) 
        ? '300px' 
        : (height && height.includes('vh')) ? height.replace('vh', 'vw') : height || '178px'
    };
  }
  @media (max-width: ${({theme}) => theme.breakpoints.transitionDesktopTablet}px) and (min-width: ${({theme}) => theme.breakpoints.tablet}px){
    max-width: 100%;
    max-height: ${({theme}) => theme.spacings.maxCardHeightOnTransition};
  }

`;

const OuterText = styled.p`
  margin: 5px 0px;
  text-align: center;
  line-height: 1;
  font-size: ${({big}) => big ? 20 : 16}px;
  color: ${({big, theme}) => big ? 'initial' : theme.colors.superDarkGrey};
`;

function Card({bgImage, bgAlign, title, subtitle, width, height, radius}) {
  return (
    <CardContainer>
      <CardImage
        src={bgImage}
        bgAlign={bgAlign}
        width={width}
        height={height}
        radius={radius}>
      </CardImage>
      <OuterText big>{title}</OuterText>
      <OuterText>{subtitle}</OuterText>
    </CardContainer>
  )
}

export default Card;