import React from "react";
import { withRouter } from "react-router";
import styled from "styled-components";

/** Custom Components */
import NotFound from 'components/Containers/NotFound';


/** Data */
import qrData from "data/qr";

const VideoContainer = styled.div`
position: relative;
width: 100%;
height: 0;
padding-bottom: 56.25%;
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
`;
const InfoContainer = styled.div`
  background-color: ${({bgColor}) => bgColor};
  width: 100%;
  padding: 20px 50px;
  color: ${({theme}) => theme.colors.white};
  border-radius: 0px 0px 5px 5px;
  h1 {
    margin: 0px;
    font-size: 22px;
    font-weight: initial;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 45px 20px;
`;

const Icon = styled.img`
  width: 35px;
`;


function QRContainer({ match }) {
  const base = match.path.split('/')[1];
  const qrId = match.params.qrId;
  const selectedQR = (qrData[base] || []).find(qr => qr.id === qrId);
  if (!selectedQR) return <NotFound />;
  return (
    <>
      <VideoContainer>
        <iframe
          title={selectedQR.title}
          src={`https://www.youtube.com/embed/${selectedQR.youtube}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen>
        </iframe>
      </VideoContainer>
      <InfoContainer bgColor={selectedQR.color}>
        <h1>{selectedQR.title}</h1>
        <p>{selectedQR.description}</p>
      </InfoContainer>
      <SocialContainer>
        <a href="https://www.instagram.com/sergiuset/" target="_blank" rel="noreferrer"><Icon src={`/assets/icons/instagram.svg`}></Icon></a>
        <a href="https://sergioolmeda.com" target="_blank" rel="noreferrer"><Icon src={`/assets/icons/web.svg`}></Icon></a>
      </SocialContainer>
    </>
  )
}

export default withRouter(QRContainer);