import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

/** Custom Components */
import Card from "components/UI/Card/Card";

/** Data */
import works from "data/works";

/** Helpers */
import sortByKey from "helpers/sortByKey";

const WorkContainer = styled.div`
	display: grid;
  grid-template-columns: ${({cardWidth}) => `repeat(auto-fit, ${cardWidth});`};
  grid-gap: 20px;
  justify-content: space-between;
  text-align: center;
  font-size: 10px;
  margin: ${({theme}) => theme.spacings.descriptionGap} 0px;

  @media (max-width: ${({theme}) => theme.breakpoints.transitionDesktopTablet}px) {
    grid-template-columns: repeat(auto-fit, calc(50% - 35px));
    grid-gap: 70px;
  }
  
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px){
    grid-template-columns: repeat(auto-fit, 100%);
    grid-gap: 20px;
    padding: 20px 0px;
    margin: 0px;
  }
`;

function WorksContainer() {
  const cardWidth = '450px';
  const cardHeight = '315px';
  const visibleAndSortedWorks = sortByKey(works.filter(work => work.visible), 'order', -1);
  return (
    <>
      <h1>Works</h1>
      <WorkContainer cardWidth={cardWidth}>
        {
          visibleAndSortedWorks.map(({_id, url, main, mainAlign, title, year}) => (
            <Link key={_id} to={`/works/${url}`}>
              <Card
                bgImage={`/assets/works/${url}/${main || 'main.png'}`}
                bgAlign={mainAlign}
                title={title}
                subtitle={year}
                width={cardWidth}
                height={cardHeight}>
              </Card>
            </Link>
          ))
        }
      </WorkContainer>
    </>
  )
}

export default WorksContainer;