const works = [
  {
    _id: 'screencapture',
    visible: true,
    order: 1,
    url: 'screencapture',
    main: 'main.jpg',
    mainAlign: 'center -170px',
    title: 'Screencapture',
    year: 2019,
    media: [
      {
        columns: 3,
        data: [
          {
            file: 'hEwAo4eM0HM',
            description: '',
          },
          {
            file: 'HJJaW4rq0EA',
            description: '',
          },
          {
            file: 'kr8NbICgWaU',
            description: '',
          },
        ],
      },        
      {
        columns: 2,
        data: [
          {
            file: 'screen-capture-01.jpg',
            description: 'Screencapture 01. 2019. Videoinstalación, 180x150 cm.',
          },
          {
            file: 'screen-capture-02.jpg',
            description: 'Screencapture 01. 2019. Videoinstalación, 180x150 cm.',
          },
        ],
      },        
      {
        columns: 1,
        data: [
          {
            file: 'screen-capture-03.jpg',
            description: '',
          },
        ],
      }, 
      {
        columns: 1,
        data: [
          {
            file: 'screen-capture-04.jpg',
            description: '',
          },
        ],
      },        
    ],
    description: [
      'Screencapture surge como necesidad de plasmar el proceso de degradación que sufre la imagen digital a través de la captura de pantalla. Esta obra instalativa se compone de una serie de archivos videográficos en los cuales se evidencia la degeneración gráfica de la imagen mediante la repetición sucesiva de capturas de pantalla a una misma imagen. De esta forma, la calidad de la imagen inicial se ve afectada propiciando que descienda, deshaciendo degradados y alterando formas y colores.',
      'Se muestra un procedimiento digital cuya función es crear réplicas de una imagen dada, sin embargo, la repetición de este proceso induce a una alteración paulatina del aspecto original de la imagen, incurriendo en una paradoja visual, la contrarréplica.',
    ],
  },
  {
    _id: 'videochroma',
    visible: true,
    order: 2,
    url: 'videochroma',
    main: 'main.jpg',
    mainAlign: 'center -46px',
    title: 'Videochroma',
    year: 2019,
    media: [
      {
        columns: 3,
        data: [
          {
            file: 'videochroma-01.jpg',
            description: '',
          },
          {
            file: 'videochroma-02.jpg',
            description: '',
          },
          {
            file: 'videochroma-03.jpg',
            description: '',
          },
        ],
        description: 'Videochroma 05. 2019. Archivo videográfico, código QR e interfaz personalizada.',
      },
      {
        columns: 1,
        data: [
          {
            file: 'videochroma-04.jpg',
            description: '',
          },
        ],
      },
      {
        columns: 1,
        data: [
          {
            file: 'videochroma-05.jpg',
            description: '',
          },
        ],
      },
      {
        columns: 2,
        data: [
          {
            file: 'videochroma-06.jpg',
            description: '',
          },
          {
            file: 'videochroma-07.jpg',
            description: '',
          },
        ],
      },
      {
        columns: 1,
        data: [
          {
            file: '7WqpQvQ7Eis',
            description: '',
          },
        ],
      },   
    ],
    description: [
      'Videochroma surge como una propuesta que plantea la resituación de la obra artística fuera de su medio expositivo tradicional (museo) con la finalidad de situarla en el medio urbano, al alcance de los peatones. Para ello, se recopilan distintos archivos videográficos de diversa índole, cumpliendo con una práctica apropiacionista que establece Internet como un terreno vasto e inabarcable. Estos clips se manipularon a partir de la aplicación de un zoom descomunal. Esta amplificación de la imagen permite desvirtuar la imagen digital y desvincularla de su referente figurativo, profundizando en los píxeles. Las imágenes tienden a formas inconcretas debido a la baja resolución y, por extensión, muestran una dimensión abstracta con un marcado componente lumínico.',
      'Con el propósito de situar estas piezas audiovisuales en relación a la interacción anónima, los archivos fueron posteriormente codificados en formato QR a fin de colocarlos al alcance de los transeúntes y democratizar el consumo de la obra. De esta manera, el peatón puede escanear fácilmente el código con su dispositivo y visualizar el vídeo, convirtiéndose en usuario de una experiencia cromática de origen digital ubicada en un entorno de tránsito cotidiano.',
    ],
  },
  {
    _id: 'volume',
    visible: true,
    order: 3,
    url: 'volume',
    mainAlign: 'center',
    title: 'Volume',
    year: 2020,
    main: 'main.jpg',
    media: [
      {
        columns: 2,
        data: [
          {
            file: 'volume-01.jpg',
            description: '',
          },
          {
            file: 'volume-02.jpg',
            description: '',
          },
          {
            file: 'volume-07.jpg',
            description: '',
          },
          {
            file: 'volume-10.jpg',
            description: 'Volume 08. 2020. Plancha de PLA intervenida, 26,5x13,5x3,5cm.',
          },
          {
            file: 'volume-08.jpg',
            description: 'Volume 10. 2020. Plancha de PLA intervenida, 27x17,5x6cm.',
          },
          {
            file: 'volume-09.jpg',
            description: 'Volume 09. 2020. Plancha de PLA intervenida, 25x7x3,5cm.',
          },
          {
            file: 'volume-06.jpg',
            description: '',
          },
          {
            file: 'volume-03.jpg',
            description: 'Volume 11. 2020. Plancha de PLA intervenida, 25x28x6,5cm.',
          },
          {
            file: 'volume-05.jpg',
            description: 'Volume 12. 2020. Plancha de PLA intervenida, 26,5x26x5cm.',
          },
          {
            file: 'volume-11.jpg',
            description: 'Volume 14. 2020. Plancha de PLA intervenida, 26,5x17,5x7cm.',
          },
          {
            file: 'volume-04.jpg',
            description: 'Volume 13. 2020. Plancha de PLA intervenida, 27x24,7x3cm.',
          },
        ],
      },
    ],
    description: [
      'Volume surge como evolución de la instalación <i>Screencapture</i> (2020). La propuesta busca sacar la imagen del plano bidimensional de la pantalla con la finalidad de colocarla en una nueva realidad física. Para ello, se han construido imágenes tridimensionales que presentan degradados cromáticos a partir de impresión 3D.',
      'Los volúmenes fueron evolucionando desde un aspecto liso y simple a formas cada vez más complejas e irregulares mediante la intervención con pistola térmica con la finalidad de modificar la estructura de la imagen a partir del calor.',
      'Las piezas de la serie Volume reflejan el agotamiento de la imagen digital causado por su saturación en los medios y la velocidad de lectura que propician un consumo masivo de la misma, avocándola a una quemadura en la cual la imagen digital se devalúa de manera sistemática.',
    ],
  },
  {
    _id: 'datacolorld',
    visible: true,
    order: 4,
    url: 'data-color-ld',
    mainAlign: 'center',
    title: 'Data Color - LD',
    year: 2020,
    main: 'main.jpg',
    firstBig: true,
    media: [
      {
        columns: 1,
        data: [
          {
            file: 'data-color-ld-03.jpg',
            description: 'Data Color (LD). 2020. Escáner e impresión Ink-jet sobre papel fotográfico, 100x70cm.',
          },
        ],
      },
      {
        columns: 2,
        data: [
          {
            file: 'data-color-ld-01.jpg',
            description: '',
          },
          {
            file: 'data-color-ld-02.jpg',
            description: '',
          },
        ],
      },        
      {
        columns: 1,
        data: [
          {
            file: 'data-color-ld-04.jpg',
            description: '',
          },
        ],
      },
      {
        columns: 1,
        data: [
          {
            file: 'data-color-ld-05.jpg',
            description: '',
          },
        ],
      }  
    ],
    description: [
      'Data Color (LD) se basa en evidenciar el registro cromático del CD mediante el escáner. La luz capturada a través del ojo de la máquina remite de forma simbólica a la información y codificación de la misma en el formato digital, convirtiéndose de esta manera el color en una metáfora visual sobre el almacenaje de datos y el consumo de archivos.'
    ],
  },
  {
    _id: 'datacolorcd',
    visible: true,
    order: 5,
    url: 'data-color-cd',
    mainAlign: 'center',
    title: 'Data Color - CD',
    year: '2020-21',
    main: 'main.jpg',
    firstBig: true,
    media: [
      {
        columns: 1,
        data: [
          {
            file: 'data-color-cd-01.jpg',
            description: 'Data Color (CD). 2020-21. Impresión toner sobre resina epoxy, 150x150cm.',
          },
        ],
      },
      {
        columns: 1,
        data: [
          {
            file: 'data-color-cd-02.jpg',
            description: '',
          },
        ],
      },
      {
        columns: 1,
        data: [
          {
            file: 'data-color-cd-03.jpg',
            description: '',
          },
        ],
        position: 'right',
      },
      {
        columns: 1,
        data: [
          {
            file: 'data-color-cd-04.jpg',
            description: '',
          },
        ],
        position: 'left',
      },
      {
        columns: 1,
        data: [
          {
            file: 'data-color-cd-05.jpg',
            description: '',
          },
        ]
      },
    ],
    description: [
      'Trabajar la luz como materia. Esto nos permite resituar la imagen digital en una nueva realidad, esta vez física. Su aspecto tangible muestra una evolución o superación con respecto a su origen, la pantalla.',
      'Aquí pasamos de frecuentar el canal de color RGB que nos muestra el monitor tras usar el escáner al modo CMYK. Del píxel al tóner. De lo virtual a lo tangible.',
      'Dotamos la luz digital de cuerpo. El polímero utilizado presenta un acabado transparente y, por tanto, genera una relación entre la luz digital y la luz natural que incide sobre la superficie.',
      'Para esta serie de Data Color, se llevó a cabo un políptico a modo de cuadrícula 4x4, con un total de 16 fragmentos de luz.',
    ],
  },
  {
    _id: 'la-piel-de-la-imagen',
    visible: true,
    order: 6,
    url: 'la-piel-de-la-imagen',
    mainAlign: 'center',
    main: 'main.jpg',
    title: 'La piel de la imagen',
    year: 2021,
    media: [
      {
        columns: 2,
        data: [
          {
            file: 'la-piel-de-la-imagen-01.jpg',
            description: 'Piel 01 (La piel de la imagen). 2021. Luz digitalizada sobre resina acrílica, 30x42cm.',
          },
          {
            file: 'la-piel-de-la-imagen-04.jpg',
            description: 'Piel 02 (La piel de la imagen). 2021. Luz digitalizada sobre resina acrílica, 30x42cm.',
          },
        ],
      },
      {
        columns: 1,
        data: [
          {
            file: 'la-piel-de-la-imagen-12.jpg',
            description: 'Piel 05 (La piel de la imagen). 2021. Luz digitalizada sobre resina acrílica, 30x42cm.',
          },
        ],
      },
      {
        columns: 2,
        data: [
          {
            file: 'la-piel-de-la-imagen-14.jpg',
            description: '',
          },
          {
            file: 'la-piel-de-la-imagen-08.jpg',
            description: '',
          },
          {
            file: 'la-piel-de-la-imagen-02.jpg',
            description: 'Piel 02 (La piel de la imagen). 2021. Luz digitalizada sobre resina acrílica, 30x42cm.',
          },
          {
            file: 'la-piel-de-la-imagen-07.jpg',
            description: '',
          },
        ],
      },
      {
        columns: 1,
        data: [
          {
            file: 'la-piel-de-la-imagen-10.jpg',
            description: '',
          }
        ],
      },
      {
        columns: 2,
        data: [
          {
            file: 'la-piel-de-la-imagen-11.jpg',
            description: 'Piel 06 (La piel de la imagen). 2021. Luz digitalizada sobre resina acrílica, 30x42cm.',
          },
          {
            file: 'la-piel-de-la-imagen-05.jpg',
            description: '',
          },
          {
            file: 'empty',
            description: '',
          },
          {
            file: 'la-piel-de-la-imagen-06.jpg',
            description: '',
          },
        ],
      },
      
    ],
    description: [
      'La luz capturada por el escáner se traduce a tonos RGB mostrados a través del monitor. Al imprimir, esta luz cambia a CMYK. Posteriormente, buscamos atravesar su condición inmaterial. Para ello, otorgamos una piel a la luz.',
      'Durante el montaje, estas imágenes son manipuladas. Se rompe su estabilidad formando pliegues y dobleces para simular el agotamiento al cual está sujeto la imagen digital debido a su actual consumo frenético.',
    ],
  },
  {
    _id: 'working-notworking',
    visible: false,
    order: 5,
    url: 'working-not-working',
    mainAlign: 'center',
    title: 'Working, not Working',
    year: 2020,
    media: [
      {
        columns: 2,
        data: [],
      }
    ],
    description: [],
  }];
    
  export default works;