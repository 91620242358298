import React from "react";
import styled from "styled-components";

/** Data */
import bio from "data/bio";

const BioCont = styled.div`
  padding: 0px 0px;
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Text = styled.p`
  width: 70%;
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px) {
    width: 80%;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;
const ItalicText = styled.span`
  font-style: italic;
`;

function HojaSalaContainer() {
  return (
    <>
      <h1>Hoja de sala</h1>
      <BioCont>
        <Text>En <BoldText><ItalicText>Self-light</ItalicText>. Luz y medio digital</BoldText> se muestran imágenes que están relacionadas con la esfera digital. El creciente auge de la tecnología y su democratización hacen que la mayoría de la población disponga de dispositivos. Todo el mundo accede a plataformas y redes sociales, o lugares de difusión de imágenes. La imagen digital es omnipresente. No transmite sino que golpea. Sus continuos bombardeos mediáticos se definen por la velocidad de transmisión y la convierten en mercancía.</Text>
        <Text>A través de los medios digitales, el artista captura y trabaja la luz presente en la esfera digital conformando nuevas imágenes que ponen de manifiesto una condición material, siendo éstas imágenes-objetos. Como aquello tan inmaterial como la imagen digital puede tornarse tangible.</Text>
      </BioCont>
      <h1>Bio</h1>
      <BioCont>
        {bio.map((__html, key) => (
          <Text key={key} dangerouslySetInnerHTML={{ __html }} />
        ))}
      </BioCont>
    </>
  )
}

export default HojaSalaContainer;