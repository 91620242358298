import React from "react";
import styled from "styled-components";

/** Custom containers */
import NotFound from "./NotFound";

/** Helpers */
import getColumns from "helpers/getColumns";
import isImage from "helpers/isImage";
import prettify from "helpers/prettify";

/** Data */
import works from "data/works";

const Row = styled.div`
  :after {
    content: "";
    display: table;
    clear: both;
  }
`;

const Column = styled.div`
  float: ${({position}) => `${position || 'left'}`};
  width: ${({numColumns, position}) => `${((position) ? 65 : 100) / numColumns}%`};
  padding: 10px;
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px){
    float: left;
    width: 100%;
  }
`;

const Img = styled.img`
  margin-top: 12px;
  width: 100%;
  :hover {
    box-shadow: 0px 0px 10px ${({theme}) => theme.colors.cardHover};
    -webkit-box-shadow: 0px 0px 10px ${({theme}) => theme.colors.cardHover};
    -moz-box-shadow: 0px 0px 10px ${({theme}) => theme.colors.cardHover};
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
  }
  min-height: 150px;
  min-width: 100%;
  background-color: ${({theme}) => theme.colors.grey};
  animation: skeleton 2s infinite;
  @keyframes skeleton {
    0% {background-color: ${({theme}) => theme.colors.grey};}
    50% {background-color: ${({theme}) => theme.colors.darkGrey};}
    100% {background-color: ${({theme}) => theme.colors.grey};}
  }
`;


const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
`;

const Description = styled.div`
  margin: ${({theme}) => theme.spacings.descriptionGap} 0px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const WorkYear = styled.span`
  font-size: 30px;
  font-weight: bold;
  margin: 0px 60px;
  color: ${({theme}) => theme.colors.darkGrey};
`;

const TextLegend = styled.p`
    display: ${({content}) => (content.length > 0) ? 'block' : 'none'};
    color: ${({theme}) => theme.colors.main_color};
    text-align: ${({position}) => `${position || 'left'}`};
    margin-top: 0px;
    @media (max-width: ${({theme}) => theme.breakpoints.tablet}px){
      text-align: left;
    }
`;

const TextLegendGeneral = styled.p`
  display: ${({content}) => (content && content.length > 0) ? 'block' : 'none'};
  padding: 0px 12px;
  clear: both;
  color: ${({theme}) => theme.colors.main_color};
  text-align: ${({position}) => `${position || 'left'}`};
  margin-top: 0px;
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px){
    text-align: left;
  }
`;

function WorksDetailContainer({workUrl}) {
  const selectedWork = works.find(work => work.url === workUrl);
  if (!selectedWork) return NotFound;

  const mediaSection = selectedWork.media.map((imageConfig, index) => {
    const columns = getColumns(imageConfig.data, imageConfig.columns);
    return (                
      <Row key={index}>
        {
          Object.keys(columns).map(column => (
              <Column key={column} numColumns={imageConfig.columns} position={imageConfig.position}>
                {
                  columns[column].map(asset => {
                    return (isImage(asset.file)) ?
                    (
                      <div key={asset.file}>
                        <a href={`/assets/works/${workUrl}/big/${asset.file}`} rel="noreferrer" target="_blank">
                          <Img src={`/assets/works/${workUrl}/${asset.file}`} alt={prettify(asset.file, asset.description || imageConfig.description)}></Img>
                        </a>
                        <TextLegend content={asset.description} position={imageConfig.position}>{asset.description}</TextLegend>
                      </div>
                    ) :
                    (
                      <div key={asset.file}>
                          {(asset.file === 'empty') ? '' : (
                          <VideoContainer>
                            <iframe
                              title={asset.file}
                              src={`https://www.youtube.com/embed/${asset.file}`}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen>
                            </iframe>
                          </VideoContainer>
                          )}
                        <TextLegend content={asset.description} position={imageConfig.position}>{asset.description}</TextLegend>
                      </div>
                    )
                  })
                }
              </Column>
          ))
        }
        <TextLegendGeneral content={imageConfig.description}>{imageConfig.description}</TextLegendGeneral>
      </Row>
    )
  });

  const descriptionSection = (
    <Description>
      {
        selectedWork.description.map((__html, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html }}></p>
        ))
      }
    </Description>
  )

  return (
    <>
      <TitleContainer>
        <h1>{selectedWork.title}</h1>
        <WorkYear>{selectedWork.year}</WorkYear>
      </TitleContainer>
      {mediaSection}
      {descriptionSection}
    </>
  );
}

export default WorksDetailContainer;