const contact = [
  {
    name: '@sergiuset',
    icon: 'instagram',
    link: 'https://www.instagram.com/sergiuset',
  },
  {
    name: 'sergio.olmedamurgui',
    icon: 'facebook',
    link: 'https://www.facebook.com/sergio.olmedamurgui',
  },
  {
    name: 'sergio.oolmeda@gmail.com',
    icon: 'email',
    link: 'mailto:sergio.oolmeda@gmail.com',
  },
  {
    name: '+34 616 047 460',
    icon: 'phone',
    link: 'tel:+34616047460',
  },
];
      
export default contact;