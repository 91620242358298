import { createGlobalStyle } from "styled-components";
import theme from "theme/";

// Global style
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'ocraext';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    unicode-range: U+000-5FF;
    src: local('ocraext'), url('/assets/fonts/ocraext.ttf') format('truetype');
  }
  html {
    box-sizing: border-box;
    font-size: 14px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    font-family: ${theme.fonts.mainFont};
    line-height: 1.5;
    font-size: 15px;
    margin: 0;
    padding: 0;
    background-color: ${theme.colors.white};
    color: ${({theme}) => theme.colors.dark};
  }
  a, a:visited, a:hover, a:active {
    text-decoration: none;
    color: inherit;
  }
  button, button:hover, button:focus {
    border: none;
    color: inherit;
    outline:0;
  }
  h3, h4 {
    border-bottom: 2px solid ${({theme}) => theme.colors.secondary_color};
  }
`;

export default GlobalStyle;
