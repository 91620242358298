const qrData = {
  videochroma: [
    {
      id: '05',
      youtube: '7WqpQvQ7Eis',
      title: 'Videochroma 05',
      description: 'Sergio Olmeda',
      color: '#2a065e',
    },
    {
      id: '04',
      youtube: 'r3zERNh1ns0',
      title: 'Videochroma 04',
      description: 'Sergio Olmeda',
      color: '#156157',
    },
    {
      id: '03',
      youtube: 'X_qdJ__5nB4',
      title: 'Videochroma 03',
      description: 'Sergio Olmeda',
      color: '#eb817d',
    },
    {
      id: '02',
      youtube: 'IRbulW0kUfc',
      title: 'Videochroma 02',
      description: 'Sergio Olmeda',
      color: '#58ad99',
    },
  ],
}
        
export default qrData;