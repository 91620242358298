import React from "react";
import styled from "styled-components";

/** Data */
import bio from "data/bio";

const BioCont = styled.div`
  padding: ${({theme}) => theme.spacings.descriptionGap} 0px;
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Text = styled.p`
  width: 70%;
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px) {
    width: 80%;
  }
`;

function BioContainer() {
  return (
    <>
      <h1>Bio</h1>
      <BioCont>
        {bio.map((__html, key) => (
          <Text key={key} dangerouslySetInnerHTML={{ __html }} />
        ))}
      </BioCont>
    </>
  )
}

export default BioContainer;