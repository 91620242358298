const menu = [
  {
    _id: 'works',
    url: 'works',
    title: 'Works',
  },
  {
    _id: 'bio',
    url: 'bio',
    title: 'Bio',
  },
  {
    _id: 'statement',
    url: 'statement',
    title: 'Statement',
  },
  {
    _id: 'contact',
    url: 'contact',
    title: 'Contact',
  },
];
  
export default menu;