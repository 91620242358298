import React from "react";
import styled from "styled-components";
import { Media } from "react-breakpoints";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

/** MenuComponents */
import DesktopMenu from "./DesktopMenu";
import BurgerMenu from 'components/Layout/BurgerMenu';

/** Helpers */
import hexToRgb from "helpers/hexToRgb";

/** Styles */
const MenuNav = styled.div`
	height: ${({theme}) => theme.spacings.headerHeight};
  background-color: ${({theme}) => theme.colors.main_color};
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(${({theme}) => hexToRgb(theme.colors.menu_bar_color)}, 1) 100%);
	position: fixed;
	width: 100%;
	z-index: 20;
`;
const Row = styled.div`
	display: grid;
	grid-template-areas: ". name menu .";
	grid-template-columns: ${({theme}) => `${theme.spacings.layoutMargin} ${theme.spacings.nameWidth} 1fr ${theme.spacings.layoutMargin}`};
	grid-template-rows: ${({theme}) => theme.spacings.headerHeight};
	grid-gap: 10px;
	overflow: hidden;
	@media (max-width: ${({theme}) => theme.breakpoints.tabletLandscape}px) {
    grid-template-columns: ${({theme}) => `${theme.spacings.layoutSmallMargin} ${theme.spacings.nameWidth} 1fr ${theme.spacings.layoutSmallMargin}`};
  }
	@media (min-width: ${({theme}) => theme.breakpoints.desktopLarge}px) {
    grid-template-columns: ${({theme}) => `1fr ${theme.spacings.nameWidth} calc(${theme.breakpoints.desktop}px - ${theme.spacings.nameWidth}) 1fr`};
  }
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px) {
    grid-template-columns: 0px ${({theme}) => theme.spacings.nameWidth} 1fr 0px;
  }
`;

const StyledLink = styled(Link)`
  grid-area: name;
  align-self: center;
`;

const ArtistName = styled.div`
  color: ${({theme}) => theme.colors.menu_items_color};
  font-size: 20px;
`

function Menu({ menu }) {
	const pathName = document.location.pathname.split("/");
	const page = (document.location.pathname !== "/") ? pathName[1] : 'inicio';
	return (
		<MenuNav>
			<Row>
				<StyledLink to="/">
          <ArtistName>
            Sergio Olmeda
          </ArtistName>
        </StyledLink>
				<Media>
					{({ breakpoints, currentBreakpoint }) =>
						breakpoints[currentBreakpoint] >= breakpoints.tablet ? (
							<DesktopMenu page={page} menu={menu} />
						) : (
							<BurgerMenu page={page} menu={menu} />
						)
					}
				</Media>
			</Row>
		</MenuNav>
	)
}

export default withRouter(Menu);
