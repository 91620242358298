import React from "react";
import styled from "styled-components";

/** Data */
import contact from "data/contact";

const ContactCont = styled.div`
  padding: ${({theme}) => theme.spacings.descriptionGap} 0px;
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Text = styled.div`
  width: 70%;
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px) {
    width: 80%;
  }
`;

const ContactInfo = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Icon = styled.img`
  width: 35px;
`;

const ContactData = styled.div`
  color: initial;
  margin-left: 20px;
  font-size: 17px;
`;

function ContactContainer() {
  return (
    <>
      <h1>Contact</h1>
      <ContactCont>
        {
          contact.map(({ name, icon, link }) => (
            <Text key={name}>
              <ContactInfo href={link} target="_blank">
                <Icon src={`/assets/icons/${icon}.svg`}></Icon>
                <ContactData>{name}</ContactData>
              </ContactInfo>
            </Text>
          ))
        }
      </ContactCont>
    </>
  )
}

export default ContactContainer;