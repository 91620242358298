import React from "react";
import { slide as Menu } from 'react-burger-menu';
import { Link } from "react-router-dom";
import styled from "styled-components";

/** Theme */
import theme from "../../theme/index";

/** Helpers */
import hexToRgb from "helpers/hexToRgb";

/** Styles */
const MenuStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '28px',
    height: '28px',
    top: `calc((${theme.spacings.headerHeight} - 28px) / 2)`,
    right: '0px',
    transform: 'translate3d(-100%, 0px, 0px)',
  },
  bmBurgerBars: {
    background: theme.colors.menu_items_color
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: theme.colors.light
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: `rgba(${hexToRgb(theme.colors.main_color)}, 0.8)`,
    padding: '2.5em 1.5em 0',
    fontSize: '1.3em'
  },
  bmMorphShape: {
    fill: theme.colors.dark,
  },
  bmItem: {
    padding: '15px 5px',
    color: theme.colors.light
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

const StyledMenu = styled(Menu)`
  .bm-menu {
    position: relative;
    overflow: hidden !important;
  }
`;

const StyledLink = styled(Link)`
	background-color: ${({selected}) => (selected) ? 'rgba(255,255,255,0.2)' : 'initial'};
  color: ${({selected, theme}) => (selected) ? theme.colors.menu_items_selected_color : theme.colors.menu_items_color};
`;

function BurgerMenu({ menu, page }) {
  return (
    <div>
      <StyledMenu 
        customBurgerIcon={ <img alt="Menu" src="/assets/general/burger.svg" /> }
        disableAutoFocus
        styles={MenuStyles}>
        {
          menu.map(menuItem => (
            <StyledLink selected={(page === menuItem.url)} key={menuItem._id} to={`/${menuItem.url}`}>
              {menuItem.title}
            </StyledLink>
          ))
        }
      </StyledMenu>
    </div>
  )
}

export default BurgerMenu;
