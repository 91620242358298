import React from "react";
import styled from "styled-components";

/** Data */
import statement from "data/statement";

const StatementCont = styled.div`
  padding: ${({theme}) => theme.spacings.descriptionGap} 0px;
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Text = styled.p`
  width: 55%;
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px) {
    width: 80%;
  }
`;

const LinkContainer = styled.div`
  display: none;
  width: 70%;
  @media (max-width: ${({theme}) => theme.breakpoints.tablet}px) {
    width: 80%;
  }
`;

const ContactInfo = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Icon = styled.img`
  width: 35px;
`;

const ContactData = styled.div`
  color: initial;
  margin-left: 20px;
  font-size: 17px;
`;

function StatementContainer() {
  return (
    <>
      <h1>Statement</h1>
      <StatementCont>
        {statement.map((__html, key) => (
          <Text key={key} dangerouslySetInnerHTML={{ __html }} />
        ))}
      </StatementCont>
      <LinkContainer>
        <ContactInfo href='/assets/downloads/portfolio.pdf' target="_blank">
          <Icon src={`/assets/icons/download-icon.png`}></Icon>
          <ContactData>Portfolio</ContactData>
        </ContactInfo>
      </LinkContainer>
    </>
  )
}

export default StatementContainer;