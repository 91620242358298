/** Containers */
import HomeContainer from "components/Containers/HomeContainer";
import WorksContainer from "components/Containers/WorksContainer";
import BioContainer from "components/Containers/BioContainer";
import StatementContainer from "components/Containers/StatementContainer";
import ContactContainer from "components/Containers/ContactContainer";
import QRContainer from "components/Containers/QRContainer";
import NotFound from 'components/Containers/NotFound';

const components = {
  inicio: HomeContainer,
  works: WorksContainer,
  bio: BioContainer,
  statement: StatementContainer,
  contact: ContactContainer,
  videochroma: QRContainer,
};

const route = route => components[route] || NotFound;

export default route;