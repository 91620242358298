import React from "react";
import { withRouter } from "react-router";

/** Containers */
import WorkDetail from "components/Containers/WorkDetailContainer";

function SubSection({ match }) {
  const { section, page } = match.params;
  const workDetailSection = (section === 'works') ? <WorkDetail workUrl={page} /> : null;

  return (
    <>
      {workDetailSection}
    </>
  )
}

export default withRouter(SubSection);
