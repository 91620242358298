const getColumns = (arr, numColumns) => {
  const finalObject = {};
  arr.map((element, index) => {
    const colName = `col-${index % numColumns}`;
    if (!finalObject[colName]) finalObject[colName] = [];
    return finalObject[colName].push(element);
  })
  return finalObject;
};

export default getColumns;