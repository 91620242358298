const feed = [

  {
    media: [
      'obras-volume-feed.jpg',
    ],
    text: [
      'Obras de la serie <i>Volume</i>',
    ],
    position: 'left',
  },
  {
    media: [
      'construccion-volume-3d-feed.jpg',
    ],
    text: [
      'Construcción de Volume en 3D',
    ],
    position: 'right',
  },
  {
    media: [
      'self-light-feed.gif',
    ],
    text: [
      'Luz propia del escáner',
    ],
    position: 'left',
  },
  
  {
    media: [
      'estudios-screencapture-feed.jpg',
    ],
    text: [
      'Estudios de Screencapture 02',
    ],
    position: 'right',
  },
  {
    media: [
      'escaneo-videochroma-feed.jpg',
    ],
    text: [
      'Escaneo de Videochroma',
      '39°28\'02.3"N 0°24\'45.1"w',
    ],
    position: 'left',
  },
  {
    media: [
      'volume-process-01-feed.jpg',
      'volume-process-02-feed.jpg',
    ],
    text: [
    'Detalles de escaneo',
    ],
    position: 'right',
  },  {
    media: [
      'videochroma-berlin-feed.jpg',
    ],
    text: [
      'Videochroma 01, Berlin Bezirk Steglitz-Zehlendorf',
    ],
    position: 'left',
  },
];

export default feed;